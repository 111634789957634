body {
  background: #201940;
  /* height: 100%;
  background-size: 'cover';
  background-attachment: 'fixed';
  max-height: 100vh;
  overflow: auto; */
  margin: 0;
  font-family: 'Noto Sans', sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
